import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import {
  HYPER_ROLL_RANKS,
  QUEUE_SYMBOLS,
  QUEUE_SYMBOLS_TO_QUEUE_NAMES,
} from "@/game-tft/constants.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import { abbreviateRank } from "@/game-tft/utils.mjs";

const PostMatchScoreboardRank = ({ leagues, showLP, queueType }) => {
  const { t } = useTranslation();
  const league = leagues && leagues[0];
  const lp = league?.league_points;

  const isHyperRoll =
    queueType === QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTftTurbo];

  if (!league) return null;

  if (isHyperRoll) {
    const rank = HYPER_ROLL_RANKS[league?.rated_tier];
    return (
      <RankedInfo>
        {typeof rank.key === "string" ? (
          <RankIcon src={StaticTFT.getHyperRollRankImage(rank.key)} />
        ) : null}
        <RankedText className="type-caption">
          {t(...rank.t)}
          <span>{league?.rated_rating}</span>
        </RankedText>
      </RankedInfo>
    );
  }
  const RankedIcon = getHextechRankIcon(league?.tier?.toLowerCase());
  return (
    <RankedInfo>
      <RankedIcon />
      <RankedText className="type-caption">
        {abbreviateRank(league)}
        {showLP && (
          <span>
            {t("lol:leaguePoints", "{{points}} LP", {
              points: lp >= 0 ? lp : 0,
            })}
          </span>
        )}
      </RankedText>
    </RankedInfo>
  );
};

export default PostMatchScoreboardRank;

const RankedInfo = styled("div")`
  display: flex;
  align-items: center;

  svg {
    height: var(--sp-5);
    width: var(--sp-5);
    margin: 0 var(--sp-1) 0 calc(-1 * var(--sp-0_5));
  }
`;
const RankedText = styled("p")`
  span {
    color: var(--shade3);
    margin-left: var(--sp-2);
  }
`;

const RankIcon = styled("img")`
  height: var(--sp-5);
  width: var(--sp-5);
  margin: 0 var(--sp-1) 0 calc(-1 * var(--sp-0_5));
`;
