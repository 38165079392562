import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import { formatDuration } from "@/app/util.mjs";
import { Subtitle } from "@/game-lol/PostMatch.style.jsx";
import { companionDirectoryReplace } from "@/game-tft/constants.mjs";
import Performance from "@/game-tft/PostMatchPerformance.jsx";
// import Round from "@/game-tft/PostMatchRound.jsx";
import Scoreboard from "@/game-tft/PostMatchScoreboard.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import { fixMatchId } from "@/game-tft/utils.mjs";
// import Timeline from "@/game-tft/PostMatchTimeline.jsx";
import Container from "@/shared/ContentContainer.jsx";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import RiotIDHeaderTitle from "@/shared/RiotIDHeaderTitle.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TABS = {
  scoreboard: {
    component: Scoreboard,
    url: "scoreboard",
    title: {
      key: "tft:matchtabs.scoreboard",
      value: "Scoreboard",
    },
  },
  performance: {
    component: Performance,
    url: "performance",
    title: {
      key: "tft:matchtabs.performance",
      value: "Performance",
    },
  },

  // timeline: {
  //   component: Timeline,
  //   url: "timeline",
  //   title: {
  //     key: "tft:matchtabs.timeline",
  //     value: "Timeline",
  //   },
  // },
  // rounds: {
  //   component: Round,
  //   url: "rounds",
  //   title: {
  //     key: "tft:matchtabs.rounds",
  //     value: "Round Breakdown",
  //   },
  // },
};

function PostMatch() {
  const { t } = useTranslation();
  const {
    parameters: [platform, name, matchId, tab],
  } = useRoute();
  const {
    tft: { matches_v2 },
  } = useSnapshot(readState);
  const correctMatchId = fixMatchId(platform, matchId);

  const [gameName, tagLine] = name.split("-");
  const matchData = matches_v2[correctMatchId];

  const participant = matchData?.info?.participants?.find((participant) => {
    return (
      participant.BLITZ_riot_account.account.game_name.toLowerCase() ===
      gameName.toLowerCase()
    );
  });

  const companionImg = companionDirectoryReplace(
    StaticTFT.getCompanionIcon(participant?.companion?.content_ID),
  );

  const links = Object.values(TABS)
    .map((tab) => {
      return {
        url: `/tft/match/${platform}/${name}/${matchId}/${tab.url}`,
        text: t(tab.title.key, tab.title.value),
        isDefault: tab.url === "scoreboard",
      };
    })
    .filter(Boolean);

  const Component = useMemo(() => {
    if (TABS[tab]) return TABS[tab].component;
    return TABS.scoreboard.component;
  }, [tab]);

  const queueId = matchData?.info?.queue_id;
  const gameDuration = matchData?.info?.game_length;
  const creation = matchData?.info?.gameCreation;

  return (
    <SharedMatchLayout
      match={matchData}
      title={RiotIDHeaderTitle({
        gameName,
        tagLine,
      })}
      titleLink={`/tft/profile/${platform}/${name}`}
      image={companionImg || FALLBACK_IMAGE_URL}
      imageLink={`/tft/profile/${platform}/${name}`}
      accentText={participant?.level}
      links={links}
      underTitle={
        <Subtitle className="type-body2">
          {queueId ? (
            <span>{StaticTFT.translateQueueType(t, queueId)}</span>
          ) : (
            ""
          )}
          {gameDuration ? (
            <span>{formatDuration(gameDuration * 1000, "mm:ss")}</span>
          ) : (
            ""
          )}
          {creation ? <TimeAgo date={creation} /> : ""}
        </Subtitle>
      }
    >
      <Container>
        <Component />
      </Container>
    </SharedMatchLayout>
  );
}

export function meta([_region, userName, _matchId]) {
  return {
    title: [
      "tft:meta.postmatch.title",
      "{{userName}}'s TFT Match Performance",
      { userName },
    ],
    description: [
      "tft:meta.postmatch.description",
      "{{userName}}'s TFT match history and how they performed.",
      { userName },
    ],
  };
}

export default memo(PostMatch);
