// This is used to represent a group of units when presented from a match (mathtile, postmatch, etc.)
import React, { memo } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobileLarge } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { Star } from "@/game-tft/CommonComponents.jsx";
import { CHAMP_BLACKLIST, ITEM_BLACKLIST } from "@/game-tft/constants.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import UnitAvatarTier from "@/game-tft/UnitAvatarTier.jsx";
import SelectIcon from "@/inline-assets/tft-select.svg";
import orderBy from "@/util/order-array-by.mjs";
import { useLazyTooltip } from "@/util/tooltip.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MatchUnitList = ({ units, unitSize, set, ...props }) => {
  const state = useSnapshot(readState);

  const champions = state.tft[set]?.champions || {};

  units = units?.filter(
    (unit) => !CHAMP_BLACKLIST(set).includes(unit?.character_id),
  );

  const unitsLength = units.length;
  const neededEmptyPieces = unitsLength < 9 ? 9 - unitsLength : 0;

  return (
    <ChampionsList {...props}>
      {orderBy(units, "rarity", "asc").map((unit, i) => {
        const { character_id, name, tier, itemNames: itemKeys } = unit;

        let items = itemKeys?.map((key) => {
          if (Array.isArray(key) && key.length === 1) {
            return key[0];
          }
          return key;
        });
        items = Array.isArray(items) && items.length === 1 ? items[0] : items;

        const unitName = character_id || name;

        const champion =
          champions?.[unitName] ||
          Object.values(champions).find((unit) => unit.name === name);

        if (!champion) return null;

        return (
          <MatchUnit
            key={`${unitName}_${i}`}
            unitName={unitName}
            unitSize={unitSize}
            itemKeys={items}
            tier={tier}
            set={set}
          />
        );
      })}
      {[...Array(neededEmptyPieces)].map((a, key) => (
        <Champion key={key}>
          <ChampionEmpty size={unitSize} />
        </Champion>
      ))}
    </ChampionsList>
  );
};

export default memo(MatchUnitList);

const MatchUnit = ({
  unitSize = 32,
  tier,
  set,
  unitName = "",
  itemKeys = [],
}) => {
  const state = useSnapshot(readState);
  const championsStaticData = state.tft?.[set]?.champions ?? {};
  const itemsStaticData = state.tft?.[set]?.items ?? {};
  const skinSetting = state.settings.tft.isSkinsEnabled;

  const champion = championsStaticData?.[unitName] ||
    Object.values(championsStaticData)?.find(
      (i) =>
        i.apiKey.toLowerCase() === unitName.toLowerCase() ||
        i.name.toLowerCase() === unitName.toLowerCase(),
    ) || {
      key: unitName,
      name: unitName,
    };
  const setItems = itemsStaticData;

  const { t } = useTranslation();
  const tooltipRef = useLazyTooltip(() =>
    ReactDOMServer.renderToStaticMarkup(
      <>
        <ToolTipName>
          <p className="type-body1">{champion?.name}</p>
          <UnitAvatarTier tier={tier} />
        </ToolTipName>

        {Array.isArray(itemsStaticData) &&
          itemsStaticData?.map((item, i) => {
            if (!item) return null;
            const unitItem = itemsStaticData[item];
            if (unitItem) {
              return (
                <ToolTipItem key={`${item}_${i}`}>
                  <img
                    src={StaticTFT.getItemImage(item, set)}
                    alt={unitItem.name}
                  />
                  <p className="type-caption">{unitItem.name}</p>
                </ToolTipItem>
              );
            }
            return (
              <ToolTipItem key={`unknownItem_${i}`}>
                <img src="" alt={`unknownItem_${i}`} />
                <p className="type-caption">
                  {t("tft:common.unknownItem", "Unknown Item")}
                </p>
              </ToolTipItem>
            );
          })}
      </>,
    ),
  );

  return (
    <Champion ref={tooltipRef} style={{ width: unitSize }}>
      <ChampionLevelApi>
        <UnitAvatarTier tier={tier} />
      </ChampionLevelApi>
      <ChampionImage
        colorlessBorder
        champKey={champion?.apiKey}
        cost={champion?.cost}
        size={unitSize}
        set={set}
        skinSetting={skinSetting}
      />
      {setItems && (
        <PieceItems>
          {Array.isArray(itemKeys) &&
            itemKeys?.map((item, i) => {
              // Don't render dummy items
              if (ITEM_BLACKLIST.includes(item)) return null;

              const unitItem = Object.values(setItems).find(
                (setItem) => setItem.apiKey === item,
              );

              if (unitItem) {
                return (
                  <img
                    src={StaticTFT.getItemImage(item, set)}
                    key={`${item}_${i}`}
                    alt={unitItem.name}
                  />
                );
              }
              return <img src="" key={`${item}_${i}`} alt={item} />;
            })}
        </PieceItems>
      )}
    </Champion>
  );
};

const ChampionsList = styled("div")`
  display: flex;
  justify-content: flex-end;

  ${mobileLarge} {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;
const Champion = styled("div")`
  position: relative;
  margin-right: var(--sp-1_5);
  margin-top: var(--sp-3);

  & > * {
    pointer-events: none;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .chosenIcon {
    position: absolute;
    z-index: 2;
    right: calc(-1 * var(--sp-0_5));
    top: -5px;
    width: 0.825rem;
  }
`;

const ChampionEmpty = styled("div")`
  box-sizing: border-box;
  border-radius: var(--br-sm);
  border: var(--sp-px) solid var(--shade7);
  background: var(--shade8);
  box-shadow: inset 0 0 0 var(--sp-px) var(--shade9);
  ${({ size }) =>
    size &&
    `height: ${size}px;
    width: ${size}px;
  `};
`;

const ChampionLevelApi = styled("div")`
  position: absolute;
  top: calc(var(--sp-4) * -1);
  left: 50%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  z-index: 2;
`;

const PieceItems = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: var(--sp-0_5);

  img {
    height: auto;
    align-self: center;
    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    width: var(--sp-3);
    border: var(--sp-px) solid var(--shade5);
  }
`;
const ToolTipName = styled("div")`
  display: flex;
  align-items: center;

  .type-body1 {
    margin-right: var(--sp-1);
  }

  ${Star} {
    height: var(--sp-4);
    width: var(--sp-4);
  }
`;
const ToolTipItem = styled("div")`
  display: flex;
  align-items: center;

  .type-caption {
    color: var(--shade2);
  }

  img {
    width: var(--sp-6);
    height: auto;
    margin-right: var(--sp-2);
    margin-bottom: var(--sp-1);
    border-radius: var(--br);
  }
`;

const _SelectStyledIcon = styled(SelectIcon)`
  position: absolute;
  z-index: 2;
  right: calc(-1 * var(--sp-0_5));
  top: -5px;
  width: 0.825rem;
`;
