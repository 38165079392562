import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import { regionsToServices } from "@/game-lol/utils/util.mjs";
import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import PostMatchScoreboardMatchTraitList from "@/game-tft/PostMatchScoreboardMatchTraitList.jsx";
import PostMatchScoreboardMatchUnitList from "@/game-tft/PostMatchScoreboardMatchUnitList.jsx";
import PostMatchScoreboardRank from "@/game-tft/PostMatchScoreboardRank.jsx";
import {
  cssCurrentProfilePlayer,
  TFTIconWithText,
  TFTScoreAvatar,
  TFTScoreContainer,
  TFTScoreHighlight,
  TFTScoreLeftBorder,
  TFTScorePlacement,
  TFTScoreProfile,
  TFTScoreRank,
  TFTScoreTraits,
  TFTScoreUnits,
  TFTScoreUsername,
  TFTUser,
  UnitsContainer,
} from "@/game-tft/PostMatchScoreboardScore.style.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import PlayerDamageIcon from "@/inline-assets/tft-player-damage.svg";
import PlayerEliminationIcon from "@/inline-assets/tft-player-elimination.svg";
import PlayerGoldRemainingIcon from "@/inline-assets/tft-player-gold-remaining.svg";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function PostMatchScoreboardScore({
  playerName,
  placement,
  avatar = "",
  gameName,
  tagLine,
  summonerRegion,
  leagues,
  units,
  traits,
  matchSet,
  goldRemaining,
  playersEliminated,
  playerDamage,
  augments,
  isBot,
}) {
  const state = useSnapshot(readState);
  return (
    <TFTScoreContainer
      role="button"
      className={playerName === gameName && cssCurrentProfilePlayer()}
      as={!isBot ? "a" : "div"}
      href={
        !isBot
          ? `/tft/profile/${regionsToServices(summonerRegion)}/${gameName}-${tagLine}`
          : null
      }
    >
      <div className="relative">
        <TFTScoreLeftBorder />
        <TFTScoreHighlight />
      </div>

      <TFTScoreProfile>
        <TFTScorePlacement>{placement}</TFTScorePlacement>
        <TFTScoreProfile>
          <TFTScoreAvatar url={avatar} />
          <TFTUser>
            <TFTScoreUsername>{gameName}</TFTScoreUsername>
            {leagues ? (
              <TFTScoreRank>
                <PostMatchScoreboardRank leagues={leagues} />
              </TFTScoreRank>
            ) : null}
          </TFTUser>
        </TFTScoreProfile>
      </TFTScoreProfile>
      <TFTScoreUnits>
        <PostMatchScoreboardMatchUnitList
          units={units}
          unitSize={26}
          set={matchSet}
        />
      </TFTScoreUnits>
      <TFTScoreTraits>
        <PostMatchScoreboardMatchTraitList
          traits={traits.slice(0, 9)}
          set={matchSet}
        />
      </TFTScoreTraits>
      <TFTIconWithText>
        {augments?.length && state.tft[matchSet]?.augments
          ? augments.map((augment) => (
              <AugmentTooltip
                key={augment}
                augment={augment}
                matchSet={matchSet}
              >
                <img
                  id="augment-img"
                  alt="augment-img"
                  src={StaticTFT.getAugmentImage(augment, matchSet)}
                  width="28"
                  height="28"
                />
              </AugmentTooltip>
            ))
          : null}
      </TFTIconWithText>
      <TFTIconWithText>
        <PlayerGoldRemainingIcon />
        <span>{goldRemaining}</span>
      </TFTIconWithText>
      <TFTIconWithText>
        <PlayerEliminationIcon />
        <span>{playersEliminated}</span>
      </TFTIconWithText>
      <TFTIconWithText>
        <PlayerDamageIcon />
        <span>{playerDamage}</span>
      </TFTIconWithText>
    </TFTScoreContainer>
  );
}

export function PostMatchScoreboardScoreMobile({
  playerName,
  placement,
  avatar = "",
  gameName,
  tagLine,
  summonerRegion,
  leagues,
  units,
  traits,
  matchSet,
  goldRemaining,
  playersEliminated,
  playerDamage,
  augments,
}) {
  const state = useSnapshot(readState);
  return (
    <TFTScoreContainer
      role="button"
      className={playerName === gameName && cssCurrentProfilePlayer()}
      href={`/tft/profile/${regionsToServices(summonerRegion)}/${gameName}-${tagLine}`}
    >
      <div>
        <TFTScoreLeftBorder />
        <TFTScoreHighlight />
      </div>

      <TFTScoreProfile>
        <div className="flex align-center gap-3">
          <TFTScorePlacement>{placement}</TFTScorePlacement>
          <div className="flex align-center gap-2">
            <TFTScoreAvatar url={avatar} />
            <TFTUser>
              <TFTScoreUsername>{gameName}</TFTScoreUsername>
              {leagues ? (
                <TFTScoreRank>
                  <PostMatchScoreboardRank leagues={leagues} />
                </TFTScoreRank>
              ) : null}
            </TFTUser>
          </div>
        </div>
        <TFTScoreTraits>
          <PostMatchScoreboardMatchTraitList
            traits={traits.slice(0, 9)}
            set={matchSet}
          />
        </TFTScoreTraits>
      </TFTScoreProfile>
      <UnitsContainer className={"flex column gap-5"}>
        <TFTScoreUnits>
          <PostMatchScoreboardMatchUnitList
            units={units}
            unitSize={40}
            set={matchSet}
          />
        </TFTScoreUnits>
        <div className="flex justify-between">
          <TFTIconWithText>
            {Array.isArray(augments) &&
            augments.length &&
            state.tft[matchSet]?.augments
              ? augments.map((augment) => (
                  <AugmentTooltip
                    key={augment}
                    augment={augment}
                    matchSet={matchSet}
                  >
                    <img
                      id="augment-img"
                      alt="augment-img"
                      src={StaticTFT.getAugmentImage(augment, matchSet)}
                      width="28"
                      height="28"
                    />
                  </AugmentTooltip>
                ))
              : ""}
          </TFTIconWithText>
          <TFTIconWithText>
            <PlayerGoldRemainingIcon />
            <span>{goldRemaining}</span>
          </TFTIconWithText>
          <TFTIconWithText>
            <PlayerEliminationIcon />
            <span>{playersEliminated}</span>
          </TFTIconWithText>
          <TFTIconWithText>
            <PlayerDamageIcon />
            <span>{playerDamage}</span>
          </TFTIconWithText>
        </div>
      </UnitsContainer>
    </TFTScoreContainer>
  );
}

export default PostMatchScoreboardScore;
